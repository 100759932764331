import { sprintf } from "@wordpress/i18n";
import { makeAutoObservable, reaction } from "mobx";

import { GlobalEntryID } from "@/data/db/migrations/entry";
import { EntryModel, globalEntryIDsAreEqual } from "@/data/models/EntryModel";
import { SyncStateRepository } from "@/data/repositories/SyncStateRepository";
import { JournalStore } from "@/data/stores/JournalStore";
import { i18n } from "@/utils/i18n";
import {
  journalId_AllEntries,
  PrimaryViewState,
} from "@/view_state/PrimaryViewState";
import { SnackbarViewState } from "@/view_state/SnackbarViewState";
import { TimelineViewState } from "@/view_state/TimelineViewState";

export const MULTI_SELECT_LIMIT = 100;

export class EntryMultiSelectViewState {
  multiSelectedEntries: GlobalEntryID[] = []; // The entries that are currently selected
  disabledJournalIDs: string[] = []; // The journals that the user cannot add entries to
  movingEntries: GlobalEntryID[] = []; // The entries that are currently being moved
  loadingEntryMoves = false;

  constructor(
    private primaryViewState: PrimaryViewState,
    private journalStore: JournalStore,
    private snackbarViewState: SnackbarViewState,
    private syncStateRepo: SyncStateRepository,
    private timelineViewState: TimelineViewState,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
    reaction(
      () => ({
        selectedEntry: primaryViewState.selectedGlobalEntryID,
        selectedJournal: primaryViewState.selectedJournalId,
      }),
      (
        { selectedEntry, selectedJournal },
        {
          selectedEntry: prevSelectedEntry,
          selectedJournal: prevSelectedJournal,
        },
      ) => {
        // Reset the multi-selection if the selected entry or selected journal changes
        // When the Entry changes, we only reset when something was selected
        if (
          (selectedEntry?.id && selectedEntry?.id !== prevSelectedEntry?.id) ||
          selectedJournal !== prevSelectedJournal
        ) {
          this.resetMultiSelection();
        }
      },
    );

    reaction(
      () => primaryViewState.journals,
      (journals) => {
        for (const journal of journals) {
          if (journal.is_shared) {
            this.journalStore
              .userCanAddEntryToJournal(journal)
              .then((canAdd) => {
                if (canAdd === "CANNOT_CREATE") {
                  this.disabledJournalIDs.push(journal.id);
                }
                if (canAdd === "CAN_CREATE") {
                  this.disabledJournalIDs = this.disabledJournalIDs.filter(
                    (id) => id !== journal.id,
                  );
                }
              });
          }
        }
      },
    );
  }

  /**
   * Adds or removes entries from the multi-selection.
   * If the entry is already in the multi-selection, it will be removed unless `keepPrevious` is true.
   * This is useful when users mix Cmd+Click and Shift+Click to select entries.
   *
   * @param globalIds The entries to toggle
   * @param keepPrevious Whether to keep the previous multi-selection
   */
  toggleMultiSelection = (globalIds: GlobalEntryID[], keepPrevious = false) => {
    // This allows us to keep the currently selected entry when we start multi-selecting
    if (
      this.multiSelectedEntries.length === 0 &&
      this.primaryViewState.selectedGlobalEntryID?.id
    ) {
      this.multiSelectedEntries.push(
        this.primaryViewState.selectedGlobalEntryID,
      );
    }

    for (const globalId of globalIds) {
      const index = this.multiSelectedEntries.findIndex(
        ({ id }) => id === globalId.id,
      );
      if (index === -1) {
        const numSelectedEntries = this.multiSelectedEntries.length;
        if (numSelectedEntries >= MULTI_SELECT_LIMIT) {
          if (globalIds.length > 1) {
            // If we've reached the limit, scroll to the last entry in the selection
            const { position, height } =
              this.timelineViewState.getPositionForEntryId(
                this.multiSelectedEntries[numSelectedEntries - 1].journal_id,
                this.multiSelectedEntries[numSelectedEntries - 1].id,
              );
            this.timelineViewState.setScrollTop(position - height);
          }

          this.snackbarViewState.newMessage(
            sprintf(
              i18n.__("You can only select up to %s entries at a time."),
              MULTI_SELECT_LIMIT.toString(),
            ),
          );
          return;
        }

        this.multiSelectedEntries.push(globalId);
      } else if (!keepPrevious) {
        this.multiSelectedEntries.splice(index, 1);
      }
    }
  };

  /**
   * Checks if an entry is in the current multi-selection.
   *
   * @param globalId The entry to check
   * @returns Whether the entry is in the multi-selection
   */
  isEntryInMultiSelection = (globalId: GlobalEntryID) => {
    return this.multiSelectedEntries.some((id) =>
      globalEntryIDsAreEqual(id, globalId),
    );
  };

  /**
   * Checks if an entry can be moved to a journal.
   * If there are several Entries being moved we only disable
   * the move if the target journal is disabled.
   *
   * If there is only one entry being moved we disable the move
   * if the target journal is in the list of moving entries.
   *
   * @param journalId The journal to check
   * @returns Whether the entry can be moved to the journal
   */
  canMoveEntryToJournal = (journalId: string) => {
    const isJournalIdInMovingEntries =
      this.movingEntries.length === 1 &&
      this.movingEntries.find((entry) => entry.journal_id === journalId);

    return (
      !this.disabledJournalIDs.includes(journalId) &&
      !isJournalIdInMovingEntries
    );
  };

  /**
   * Checks if an entry can be moved.
   * We allow the move action from the "All Entries" view.
   * We do not allow the move action from a shared journal.
   * Shared Entries moved from the "All Entries" view will still not
   * be moved, but we handle it in the `useEntryMove` hook and display the appropriate message.
   *
   * @param entry The entry to check
   * @returns Whether the entry can be moved
   */
  isMoveAllowed = (entry: EntryModel) => {
    const isAllEntries =
      this.primaryViewState.selectedJournalId === journalId_AllEntries;

    return !entry.isShared || isAllEntries;
  };

  /**
   * Stores the entries that are being moved.
   *
   * @param globalIds The entries to move
   */
  startDraggingEntries = (globalIds: GlobalEntryID[]) => {
    this.movingEntries = globalIds;
  };

  /**
   * Resets the moving entries.
   */
  stopDraggingEntries = () => {
    this.movingEntries = [];
  };

  /**
   * Resets the multi-selection.
   */
  resetMultiSelection = () => {
    this.multiSelectedEntries = [];
  };

  /**
   * Sets the loading state of the entry moves and locks the sync.
   * This is used to prevent conflicting feed updates while we are moving entries locally.
   *
   * @param loading Whether the entry moves are loading
   */
  setLoadingEntryMoves = (loading: boolean) => {
    if (loading) {
      this.syncStateRepo.setLock();
    } else {
      this.syncStateRepo.clearLock();
    }
    this.loadingEntryMoves = loading;
  };

  /**
   * Checks if entries are currently being moved.
   *
   * @returns Whether entries are currently being moved
   */
  get isMovingEntries() {
    return this.movingEntries.length > 0;
  }
}
