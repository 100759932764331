import { __ } from "@wordpress/i18n";
import { useState } from "react";

import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { SearchBox } from "@/components/SearchBox";
import { CategoryWithTemplates } from "@/components/Templates";
import { TemplateGalleryCategoryList } from "@/components/Templates/TemplateGalleryCategoryList";
import { PBCTemplate, SelectedTemplate } from "@/data/db/migrations/template";

type TemplateGalleryListProps = {
  modalHeight: number;
  handleClick: (selected: SelectedTemplate) => void;
  activeTemplate: PBCTemplate;
  isLoading: boolean;
  categories: Map<string, CategoryWithTemplates>;
};

export const TemplateGalleryList: React.FC<TemplateGalleryListProps> = ({
  modalHeight,
  handleClick,
  activeTemplate,
  isLoading,
  categories,
}) => {
  const [search, setSearch] = useState<string | null>(null);

  const categoryList = Array.from(categories.values());
  if (isLoading) {
    const heightOfLoading = 100;
    const numberToShow = Math.ceil(modalHeight / heightOfLoading);
    return (
      <div sx={{ p: 3 }}>
        {Array.from(Array(numberToShow).keys()).map((i) => (
          <LoadingMediaPlaceholder
            key={i}
            width={300}
            height={heightOfLoading}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <SearchBox
        value={search}
        setInputValue={(value: string | null) => setSearch(value)}
        placeholder={__("Search Templates")}
      />
      <div
        sx={{
          overflowY: "scroll",
          height: `calc(${modalHeight}px - 60px - 48px)`,
          p: 3,
        }}
      >
        {categoryList.map((category) => {
          return (
            <TemplateGalleryCategoryList
              key={category.id}
              category={category}
              handleClick={handleClick}
              activeTemplate={activeTemplate}
              search={search}
            />
          );
        })}
      </div>
    </>
  );
};
