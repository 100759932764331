import { I18nProvider } from "@wordpress/react-i18n";
import { lazy, Suspense } from "react";
import { Route, Router, Switch } from "wouter";

import { AuthRoute } from "@/components/AuthRoute";
import { D1SnackbarGlobal } from "@/components/D1SnackbarGlobal";
import { FullScreenLoadingMessage } from "@/components/FullScreenLoadingMessage";
import { UpdateAvailable } from "@/components/UpdateAvailable";
import { UsageStatisticsBanner } from "@/components/UsageStatisticsBanner";
import { AuthProvider } from "@/data/hooks/AuthProvider";
import { LayoutProvider } from "@/data/hooks/LayoutProvider";
import CompleteQRLogin from "@/pages/CompleteQRLogin";
import { QRLogin } from "@/pages/login/QRLogin";
import PrefilledEntryPreview from "@/pages/prefilledUrl/PrefilledEntryPreview";
import { DayOneThemeProvider } from "@/styles/DayOneThemeProvider";
import { GlobalStyles } from "@/styles/GlobalStyles";
import { i18n } from "@/utils/i18n";

export const App = () => {
  const PrivateLayout = lazy(() => import("@/layouts/PrivateLayout"));
  const SuspensePrivateLayout = () => {
    return (
      <Suspense fallback={<FullScreenLoadingMessage message="Loading..." />}>
        <PrivateLayout />
      </Suspense>
    );
  };

  const TestUserLogin = lazy(() => import("@/pages/login/TestUserLogin"));
  const NotFound = lazy(() => import("@/pages/NotFound"));
  const Support = lazy(() => import("@/pages/support"));
  const SubscribeFullScreen = lazy(
    () => import("@/components/PremiumUpgrade/SubscribeFullScreen"),
  );
  const SubscribeSuccess = lazy(
    () => import("@/components/PremiumUpgrade/SubscribeSuccess"),
  );
  const CompleteEmailChange = lazy(() => import("@/pages/CompleteEmailChange"));
  const InstagramAuth = lazy(() => import("@/pages/InstagramAuth"));
  const OAuthConnect = lazy(() => import("@/pages/OAuthConnect"));
  const AcceptJournalInvitePage = lazy(
    () => import("@/pages/acceptJournalInvite"),
  );
  const Logout = lazy(() => import("@/pages/logout"));
  const Recover = lazy(() => import("@/pages/recover"));
  const CompleteRecovery = lazy(
    () => import("@/pages/recover/CompleteRecovery"),
  );
  const Redeem = lazy(() => import("@/pages/redeem"));
  const RedeemInfo = lazy(() => import("@/pages/redeem/RedeemInfo"));
  const Signup = lazy(() => import("@/pages/signup"));
  const CompleteSignup = lazy(() => import("@/pages/signup/CompleteSignup"));
  const Login = lazy(() => import("@/pages/login"));
  const CloudKit = lazy(() => import("@/pages/login/CloudKit"));
  const UndeleteAccount = lazy(() => import("@/pages/UndeleteAccount"));
  const AcceptInvite = lazy(() => import("@/pages/invite"));
  const EncryptionInfo = lazy(() => import("@/pages/encryption"));
  const Checkout = lazy(() => import("@/pages/checkout"));
  const ForceLogout = lazy(() => import("@/pages/logout/force"));

  return (
    <DayOneThemeProvider>
      <I18nProvider i18n={i18n}>
        <GlobalStyles />
        <UsageStatisticsBanner />
        <AuthProvider>
          <D1SnackbarGlobal />
          <UpdateAvailable />
          <Router>
            <Switch>
              <AuthRoute path="/test/login">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <TestUserLogin />
                </Suspense>
              </AuthRoute>
              <AuthRoute
                privatePath
                path="/journals/:journalId/prompt/:promptId"
              >
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/journals/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/journals/:journalId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/home/:journalId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/all">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/all/prompt/:promptId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/all/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/settings">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/shared-journals-intro">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/journal-not-found">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/journal-encrypted">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/entry-not-found">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              {/* Start Calendar View Routes */}
              <AuthRoute privatePath path="/calendar">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/all">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/all/prompt/:promptId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/:journalId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute
                privatePath
                path="/calendar/:journalId/prompt/:promptId"
              >
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/all/day/:date">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/:journalId/day/:date">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/all/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/calendar/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              {/* End Calendar View Routes */}

              {/* Start Media View Routes */}
              <AuthRoute privatePath path="/media">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/all">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/all/prompt/:promptId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/:journalId/prompt/:promptId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/:journalId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute
                privatePath
                path="/media/all/:journalId/prompt/:promptId"
              >
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/all/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              <AuthRoute privatePath path="/media/:journalId/:entryId">
                {() => <SuspensePrivateLayout />}
              </AuthRoute>
              {/* End Media View Routes */}

              <AuthRoute path="/accept-journal-invite">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <AcceptJournalInvitePage />
                </Suspense>
              </AuthRoute>

              <AuthRoute privatePath path="/invite/accept">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <AcceptInvite />
                </Suspense>
              </AuthRoute>

              <AuthRoute privatePath path="/instagram/auth">
                {() => (
                  <Suspense fallback="Loading...">
                    <InstagramAuth />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute privatePath path="/oauth">
                {() => (
                  <LayoutProvider>
                    <Suspense fallback={<FullScreenLoadingMessage />}>
                      <OAuthConnect />
                    </Suspense>
                  </LayoutProvider>
                )}
              </AuthRoute>

              <AuthRoute privatePath path="/subscribe/success">
                {() => (
                  <Suspense fallback="Loading...">
                    <SubscribeSuccess />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute privatePath path="/subscribe">
                {() => (
                  <LayoutProvider>
                    <Suspense fallback="Loading...">
                      <SubscribeFullScreen />
                    </Suspense>
                  </LayoutProvider>
                )}
              </AuthRoute>

              <AuthRoute privatePath path="/logout">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <Logout />
                </Suspense>
              </AuthRoute>

              <AuthRoute privatePath path="/qr-login">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <CompleteQRLogin />
                </Suspense>
              </AuthRoute>

              <AuthRoute path="/force-logout">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <ForceLogout />
                </Suspense>
              </AuthRoute>

              <AuthRoute path="/login">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <Login />
                  </Suspense>
                )}
              </AuthRoute>
              <AuthRoute path="/loginWithQRCode">
                {() => (
                  <LayoutProvider>
                    <Suspense fallback={<FullScreenLoadingMessage />}>
                      <QRLogin />
                    </Suspense>
                  </LayoutProvider>
                )}
              </AuthRoute>
              <AuthRoute path="/loginWithCloudKit">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <CloudKit />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/recover">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <Recover />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/signup">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <Signup />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/completeSignup">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <CompleteSignup />
                  </Suspense>
                )}
              </AuthRoute>
              <AuthRoute path="/completeRecovery">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <CompleteRecovery />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/settings/change-email">
                {() => (
                  <Suspense fallback="Loading...">
                    <CompleteEmailChange />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/undelete-account">
                {() => (
                  <Suspense fallback="Loading...">
                    <UndeleteAccount />
                  </Suspense>
                )}
              </AuthRoute>

              <AuthRoute path="/redeemInfo">
                {() => (
                  <Suspense fallback={<FullScreenLoadingMessage />}>
                    <RedeemInfo />
                  </Suspense>
                )}
              </AuthRoute>
              <AuthRoute path="/redeem">
                <Suspense fallback={<FullScreenLoadingMessage />}>
                  <Redeem />
                </Suspense>
              </AuthRoute>

              <AuthRoute path="/newentry">
                {() => (
                  <LayoutProvider>
                    <PrefilledEntryPreview />
                  </LayoutProvider>
                )}
              </AuthRoute>

              <AuthRoute path="/support">
                {() => (
                  <LayoutProvider>
                    <Suspense fallback="Loading...">
                      <Support />
                    </Suspense>
                  </LayoutProvider>
                )}
              </AuthRoute>

              <AuthRoute path="/checkout">
                {() => (
                  <LayoutProvider>
                    <Suspense fallback="Loading...">
                      <Checkout />
                    </Suspense>
                  </LayoutProvider>
                )}
              </AuthRoute>

              <AuthRoute path="/encryption">
                {() => (
                  <Suspense fallback="Loading...">
                    <EncryptionInfo />
                  </Suspense>
                )}
              </AuthRoute>

              <Route>
                {() => (
                  <Suspense fallback="Loading...">
                    <NotFound />
                  </Suspense>
                )}
              </Route>
            </Switch>
          </Router>
        </AuthProvider>
      </I18nProvider>
    </DayOneThemeProvider>
  );
};
