export type ActiveModalRoutes = {
  // Layer 1 routes are exclusive to each other. If a new layer 1 route is opened, the previous one is closed.
  1: ModalRouteLayer1 | null;
  // Layer 2 routes can be opened on top of layer 1 routes. They can display on top of an active layer 1 route,
  // but they are exclusive to each other. If a new layer 2 route is opened, the previous one is closed.
  // ATTENTION: Layer 1 **does not have to be open for layer 2 to be open**.
  // Layer 2 routes can be open all by themselves. But it's called layer "2" to indicate
  // that when it's open, it's opened on top of a layer 1 route.
  2: ModalRouteLayer2 | null;
  3: ModalRouteLayer3 | null;
};

export type ModalRoute =
  | NewJournalModalRoute
  | NewSharedJournalModalRoute
  | EditJournalModalRoute
  | SettingsModalRoute
  | SupportFormModalRoute
  | PremiumUpgradeRoute
  | NotificationRoute
  | AddEntryToJournalRoute
  | SharedJournalSettingsModalRoute
  | SharedJournalMemberModalRoute
  | PasskeysManagerRoute
  | E2EEKeyModalRoute
  | SharedJournalInviteLink
  | TemplateManagementRoute
  | PendingApprovalsModalRoute
  | ShowSharedJournalsInfoModalRoute
  | ShowSearchModalRoute
  | ShowAdvancedSearchModalRoute
  | ShowRecentPrompts
  | SyncDetailsModalRoute
  | SharedJournalFeaturesModalRoute
  | EntryMetadataModalRoute
  | ExportModalRoute;

export type ModalRouteLayer1 = Extract<ModalRoute, { layer: 1 }>;
export type ModalRouteLayer2 = Extract<ModalRoute, { layer: 2 }>;
export type ModalRouteLayer3 = Extract<ModalRoute, { layer: 3 }>;

type NewJournalModalRoute = BaseModalRoute<"new_journal", 1>;
type NewSharedJournalModalRoute = BaseModalRoute<"new_shared_journal", 1>;

// Edit journal can be opened from within settings, so it has to be on layer 2.
type EditJournalModalRoute = BaseModalRoute<"edit_journal", 2> & {
  journal_id: string;
};
type AddEntryToJournalRoute = BaseModalRoute<"add_entry_to_journal", 1> & {
  journal_disabled: boolean;
};

type SettingsModalRoute = BaseModalRoute<"settings", 1> & {
  tab: settingsTab;
  element?: string;
};

type SharedJournalSettingsModalRoute = BaseModalRoute<
  "shared_journal_settings",
  1
> & { journal_id: string };

type SyncDetailsModalRoute = BaseModalRoute<"sync_details", 1>;

type SupportFormModalRoute = BaseModalRoute<"support_form", 1>;

type ShowSearchModalRoute = BaseModalRoute<"show_search", 1>;

type ShowAdvancedSearchModalRoute = BaseModalRoute<"show_advanced_search", 1>;

type ShowRecentPrompts = BaseModalRoute<"show_recent_prompts", 1>;

type PremiumUpgradeRoute = BaseModalRoute<"premium_upgrade", 2> & {
  type?: string;
};

type NotificationRoute = BaseModalRoute<"notifications", 1>;

type SharedJournalMemberModalRoute = BaseModalRoute<
  "shared_journal_member",
  3
> & { user_id: string; journal_id: string };

type PasskeysManagerRoute = BaseModalRoute<"passkeys_manager", 2>;

type E2EEKeyModalRoute = BaseModalRoute<"e2ee_masterkey", 2> & {
  opened_from: EncryptionModalOpenMethods;
  is_creating_shared_journal: boolean;
  agreed_to_create_key: boolean;
  deny_retrieve: boolean;
};

type SharedJournalInviteLink = BaseModalRoute<"shared_journal_invite", 3> & {
  journal_id: string;
};

type EntryMetadataModalRoute = BaseModalRoute<"entry_metadata", 1> & {
  entry_id: string;
  journal_id: string;
};

type ExportModalRoute = BaseModalRoute<"export", 3> & {
  journal_id: string;
};

type TemplateManagementRoute = BaseModalRoute<"template_management", 1> & {
  tab: templatesTab;
  template_id?: string;
};

type ShowSharedJournalsInfoModalRoute = BaseModalRoute<
  "show_shared_journals_info",
  1
> & { openNewOnClose: boolean };

export type PendingApprovalsModalRoute = BaseModalRoute<"pending_approvals", 3>;

export type SharedJournalFeaturesModalRoute = BaseModalRoute<
  "shared_journal_features",
  3
> & { user: string };

//
// Smaller types that supplement the above
//
type BaseModalRoute<Name extends string, Layer extends 1 | 2 | 3> = {
  name: Name;
  layer: Layer;
};

export type settingsTab = (typeof settingsTabs)[number];

// A type (and value) that represents the tabs in the settings modal.
// Please note that some of these tabs may not actually display to the
// user, depending on what feature flags or developer settings are enabled.
export const settingsTabs = [
  "account",
  "entries",
  "journal",
  "appearance",
  "advanced",
  "dailyPrompts",
  "featureFlags",
  "developer",
  "import",
  // The "as const" is important here, it makes the type of validPanels
  // be a tuple of the strings in the array, instead of just a string[]
] as const;

export type templatesTab = (typeof templatesTabs)[number];
export const templatesTabs = ["gallery", "myTemplates"] as const;

export type EncryptionModalOpenMethods =
  | "settings"
  | "settings-advanced"
  | "sidebar"
  | "new_shared_journal"
  | "template_management"
  | "retrieve_encryption_key"
  | "accept_invite"
  | "enter_key_modal"
  | "search"
  | "encryption_info"
  | "add_entry"
  | "prefilled_url";
