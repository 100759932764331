import { useBlockProps } from "@wordpress/block-editor";
import { Disabled } from "@wordpress/components";
import { video } from "@wordpress/icons";

import { FailedMedia } from "@/components/Editor/components/FailedMedia";
import { LoadingMediaPlaceholder } from "@/components/Editor/components/LoadingMediaPlaceholder";
import { useMoment } from "@/data/hooks/moments";
import { dayOneBlue } from "@/styles/theme";
import { getMediaWidth } from "@/utils/gallery";

export type BlobVideoProps = {
  journalId: string;
  entryId: string;
  clientId: string;
  aspectRatio?: number;
  isSelected: boolean;
  galleryHeight?: number;
};

// Display a syncable video by MD5
export const SyncableVideo: React.FC<BlobVideoProps> = ({
  journalId,
  entryId,
  clientId,
  aspectRatio,
  isSelected,
  galleryHeight,
}) => {
  const { blob, isLoading, moment } = useMoment(journalId, entryId, clientId);
  const blockProps = useBlockProps({
    style: {
      border: "none",
      height: galleryHeight ? `${galleryHeight}px` : "auto",
      width:
        aspectRatio && galleryHeight
          ? `${getMediaWidth(aspectRatio, galleryHeight)}px`
          : undefined,
      boxShadow:
        aspectRatio !== undefined
          ? `0 0 0 1px ${isSelected ? dayOneBlue : "transparent"}`
          : undefined,
    },
  });

  if (isLoading) {
    if (aspectRatio) {
      return (
        <LoadingMediaPlaceholder
          styles={{
            flexBasis: 0,
            flexGrow: aspectRatio,
            aspectRatio,
          }}
        />
      );
    }

    if (moment) {
      return (
        <LoadingMediaPlaceholder
          key={moment.id}
          height={moment.height || undefined}
          width={moment.width || undefined}
        />
      );
    }

    return <LoadingMediaPlaceholder />;
  }

  if (blob) {
    return (
      <div
        {...blockProps}
        data-momentid={moment?.id}
        sx={{
          "& video": {
            p: "1px",
            borderRadius: aspectRatio ? undefined : "3px",
            border: aspectRatio ? undefined : "3px solid",
            borderColor: isSelected ? dayOneBlue : "transparent",
            objectFit: "cover",
            height: "100%",
          },
          // This makes sure the "Disabled" component shows our video fully
          " > div": {
            display: "flex",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          },
        }}
      >
        <Disabled isDisabled={!isSelected}>
          <video controls>
            <source src={URL.createObjectURL(blob)} />
          </video>
        </Disabled>
      </div>
    );
  }

  if (aspectRatio) {
    return (
      <div
        {...blockProps}
        sx={{
          div: {
            my: 0,
          },
        }}
      >
        <FailedMedia icon={video} moment={moment} />
      </div>
    );
  }

  return <FailedMedia icon={video} moment={moment} />;
};
