import { Button, __experimentalText as Text } from "@wordpress/components";
import { __, _n } from "@wordpress/i18n";
import { differenceInDays, sub } from "date-fns";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { d1Classes } from "@/D1Classes";
import analytics from "@/analytics";
import { EVENT } from "@/analytics/events";
import { CommentMenu } from "@/components/Editor/components/comments/CommentMenu";
import { EditComment } from "@/components/Editor/components/comments/EditComment";
import { Avatar } from "@/components/SharedJournals/Avatar";
import { ReactionsModal } from "@/components/SharedJournals/ReactionsModal";
import { AddReaction } from "@/components/icons/AddReaction";
import { CommentDBRow } from "@/data/db/migrations/comment";
import { CommentReactionDBRow } from "@/data/db/migrations/reaction";
import { useDevice } from "@/data/hooks/LayoutProvider";
import { useSubscription } from "@/hooks/d1_hooks";
import { useSharedJournalParticipant } from "@/hooks/useSharedJournalParticipant";
import { getRelativeTime } from "@/utils/date-helper";
import { decodeRichTextJson, getCommentContentFromRTJ } from "@/utils/rtj";
import { primaryViewState, viewStates } from "@/view_state/ViewStates";

type Props = {
  comment: CommentDBRow;
  userAvatar: JSX.Element;
  active: boolean;
  entryCreator: string;
};

export const Comment: React.FC<Props> = observer(
  ({ comment, userAvatar, active, entryCreator }) => {
    const author = useSharedJournalParticipant(comment.author_id);
    const [refreshDate, setRefreshDate] = useState(new Date());
    const [edit, setEdit] = useState(false);
    const [showReactionsModal, setShowReactionsModal] = useState(false);
    const { isMobile } = useDevice();

    const user = primaryViewState.user;
    const journal = primaryViewState.getJournalById(comment.journal_id);
    const showReactions = journal?.is_shared;
    const commentDate = new Date(comment.created_at);

    const reactions =
      useSubscription<CommentReactionDBRow[]>(
        (cb) =>
          d1Classes.reactionStore.subscribeToReactionByComment(
            comment.journal_id,
            comment.entry_id,
            comment.id,
            cb,
          ),
        [comment],
      ) ?? [];

    const userReaction = reactions.find((r) => r.user_id === user?.id);
    const reactionCount = reactions.length;

    const reactionLabel =
      reactionCount === 0
        ? __("Like")
        : `${reactionCount} ${_n("Like", "Likes", reactionCount)}`;

    useEffect(() => {
      let unsub = () => {};

      const oneWeekAgo = sub(new Date(), { days: 7 });
      if (differenceInDays(commentDate, oneWeekAgo) < 7) {
        const interval = setInterval(() => {
          setRefreshDate(new Date());
        }, 1000 * 60);
        unsub = () => clearInterval(interval);
      }
      return () => {
        unsub();
      };
    }, [refreshDate]);

    if (!user) {
      return null;
    }

    const toggleUserReaction = () => {
      if (!userReaction) {
        d1Classes.reactionStore.addCommentReaction(
          comment.journal_id,
          comment.entry_id,
          comment.id,
          "like",
        );
        analytics.tracks.recordEvent(EVENT.entryCommentReactionAdded, {
          entry_id: comment.entry_id,
        });
      } else {
        d1Classes.reactionStore.removeCommentReaction(
          comment.journal_id,
          comment.entry_id,
          comment.id,
          user.id,
        );
        analytics.tracks.recordEvent(EVENT.entryCommentReactionDeleted, {
          entry_id: comment.entry_id,
        });
      }
    };

    const canUserDelete =
      user.id === comment.author_id ||
      user.id === entryCreator ||
      user.id === journal?.owner_id;

    const showMenu = user.id === comment.author_id || canUserDelete;

    let avatar = null;
    let name = "";
    if (user.id === comment.author_id) {
      avatar = userAvatar;
      name = user.display_name || "";
    } else {
      avatar = author ? <Avatar user={author} size={1} /> : null;
      name = author?.name || "";
    }

    const rtj = decodeRichTextJson(comment.content);
    const content = getCommentContentFromRTJ(rtj);

    const dateFormat = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    } as Intl.DateTimeFormatOptions;

    const dateStr = getRelativeTime(commentDate, dateFormat);

    return (
      <>
        <motion.div
          sx={{
            display: "flex",
            alignItems: "flex-start",
            my: 4,
            p: 2,
            borderRadius: 3,
          }}
          initial={{
            backgroundColor: active
              ? "var(--theme-ui-colors-surfaceHighlightAnimateStart)"
              : "transparent",
          }}
          animate={{
            backgroundColor: active
              ? "var(--theme-ui-colors-surfaceHighlightAnimateEnd)"
              : "transparent",
          }}
          transition={{ duration: 5 }}
        >
          <button
            aria-label={__("View user")}
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              mt: 1,
              p: 0,
              "& img, & div": {
                width: "32px",
                height: "32px",
                lineHeight: "32px",
                fontSize: 2,
              },
            }}
            onClick={() => {
              viewStates.modalRouter.showSharedJournalMember(
                comment.journal_id,
                comment.author_id,
              );
            }}
          >
            {avatar}
          </button>
          <div sx={{ ml: 3, flexGrow: 1 }}>
            <div
              sx={{
                mb: 1,
                fontSize: 1,
                lineHeight: 2,
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <strong>{name}</strong>
              <div>
                <Text
                  sx={{
                    lineHeight: 2,
                    textAlign: "left",
                    color: "textSecondary",
                    py: 0,
                    pl: isMobile ? 0 : 2,
                    fontSize: 0,
                  }}
                  truncate
                  title={commentDate.toLocaleDateString(undefined, dateFormat)}
                >
                  {dateStr}
                </Text>
              </div>
            </div>
            {!edit && (
              <div
                sx={{ whiteSpace: "pre-wrap", fontSize: 1, lineHeight: 2 }}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {edit && (
              <EditComment comment={comment} closeEdit={() => setEdit(false)} />
            )}
            {showReactions && (
              <div sx={{ height: 4 }}>
                <Button
                  variant="link"
                  sx={{
                    "&&": {
                      textDecoration: "none",
                    },
                  }}
                >
                  <Text
                    // Temporarily disabling showing reaction modal. Change back to setShowReactions(true) to turn it back
                    onClick={toggleUserReaction}
                    sx={{
                      textAlign: "left",
                      color: "textSecondary",
                      fontSize: 0,
                      lineHeight: 2,
                      mt: 1,
                    }}
                    truncate
                    title={reactionLabel}
                  >
                    {reactionLabel}
                  </Text>
                </Button>
              </div>
            )}
          </div>
          <div sx={{ ml: 2, flexShrink: 0 }}>
            {showMenu && (
              <CommentMenu
                comment={comment}
                user_id={user.id}
                openEdit={() => setEdit(true)}
                isEditing={edit}
                canUserDelete={canUserDelete}
              />
            )}
            {showReactions && (
              <Button
                onClick={toggleUserReaction}
                sx={{
                  svg: {
                    color: userReaction ? journal?.color : "textTertiary",
                  },
                }}
              >
                <AddReaction />
              </Button>
            )}
          </div>
        </motion.div>
        {showReactionsModal && (
          <ReactionsModal
            reactions={reactions}
            journalColor={journal?.color}
            handleClose={() => setShowReactionsModal(false)}
            journalId={comment.journal_id}
          />
        )}
      </>
    );
  },
);
