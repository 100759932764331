import { __ } from "@wordpress/i18n";
import { liveQuery } from "dexie";

import { Sentry } from "@/Sentry";
import { KeyValueStore } from "@/data/db/KeyValueStore";
import { DODexie } from "@/data/db/dexie_db";
import {
  CommentSendable,
  EntryMoveSendable,
  EntrySendable,
  OutboxItem,
  OutboxResult,
  Sendable,
  TemplateSendable,
  UploadOriginalMomentSendable,
} from "@/data/models/OutboxTypes";
import { makeDebugLogger } from "@/utils/debugLog";
import { SYNC_DELAY_TIME } from "@/worker/SyncWorkerTypes";

export function isOriginalMediaSendable(
  item: Sendable,
): item is UploadOriginalMomentSendable {
  return item.type === "OriginalMedia";
}

export function isEntrySendable(item: Sendable): item is EntrySendable {
  return item.type === "Entry";
}

export function isEntryMoveSendable(item: Sendable): item is EntryMoveSendable {
  return item.type === "Entry" && item.action === "MOVE";
}

export function isCommentSendable(item: Sendable): item is CommentSendable {
  return item.type === "Comment";
}

export function isTemplateSendable(item: Sendable): item is TemplateSendable {
  return item.type === "Template";
}

export class Outbox {
  lastPushDateTime: number | null = null;
  pushLocked = false;
  logDebug = makeDebugLogger("Outbox", false);
  constructor(
    private db: DODexie,
    kv: KeyValueStore,
  ) {
    kv.subscribe<boolean>("debug-logging", (value) => {
      this.logDebug = makeDebugLogger("Outbox", !!value);
    });
  }

  callbacks: (() => void)[] = [];

  private itemIsRetryableOrFresh(item: OutboxItem) {
    if (item.failedDate == null) return true;
    // 5 seconds ago
    return item.failedDate < new Date(Date.now() - 5 * 1000);
  }

  getSize() {
    return this.db.outbox_items.count();
  }

  subscribeToSize(callback: (size: number) => void) {
    const sub = liveQuery(() => {
      return this.db.outbox_items.count();
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => sub.unsubscribe();
  }

  subscribeToAll(callback: (items: OutboxItem[]) => void) {
    const sub = liveQuery(() => {
      return this.db.outbox_items.orderBy("userModifiedAt").toArray();
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => sub.unsubscribe();
  }

  subscribeToEntryMove(
    entryId: string,
    callback: (items: EntryMoveSendable[] | null) => void,
  ) {
    const sub = liveQuery(() => {
      return this.getAllEntryMoves(entryId);
    }).subscribe(callback, (err) => Sentry.captureException(err));
    return () => sub.unsubscribe();
  }

  remove(object: Sendable) {
    return this.db.outbox_items.delete(`${object.type}:${object.id}`);
  }

  async add(object: Sendable) {
    this.logDebug("Adding to outbox: ", object);
    const userModifiedAt = Date.now();

    await this.db.outbox_items.put({
      ...object,
      id: `${object.type}:${object.id}`,
      userModifiedAt,
    });
    this.callbacks.forEach((cb) => cb());
  }

  onAdd(callback: () => void) {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }

  async all() {
    const items = await this.db.outbox_items
      .orderBy(`userModifiedAt`)
      .toArray();
    return items;
  }

  failedCount() {
    return this.db.outbox_items
      .filter((item) => item.failedDate != null)
      .count();
  }

  async hasRetryableItems() {
    const count = await this.db.outbox_items
      .filter(this.itemIsRetryableOrFresh)
      .count();
    return count > 0;
  }

  async hasItemsForJournal(journalId: string) {
    const count = await this.db.outbox_items
      .where({ journalId: journalId })
      .count();
    return count > 0;
  }

  async updateExistingEntryMove(
    newItem: EntryMoveSendable,
    oldItem: EntryMoveSendable,
  ) {
    this.logDebug("Updating outbox item: ", newItem);

    // If the destination journal is the same as the original journal,
    // we can ignore the move entirely.
    if (newItem.destinationJournalId === oldItem.journalId) {
      await this.db.outbox_items.delete(oldItem.id);
      return;
    }
    // We need to keep the old item's userModifiedAt
    const updatedItem = {
      ...oldItem,
      ...newItem,
    };
    await this.db.outbox_items.update(oldItem.id, updatedItem);
    return updatedItem;
  }

  async getAllEntryMoves(entryId: string) {
    return this.db.outbox_items
      .where({ entryId: entryId, action: "MOVE" })
      .toArray() as unknown as EntryMoveSendable[];
  }

  async hasItemsToPush() {
    // We have outbox items to push if there are non-failed items
    // in the queue or if there are failed items that were failed more than
    // 5 seconds ago.
    const size = await this.getSize();
    if (size == 0) return false;
    if (await this.hasRetryableItems()) return true;
    if ((await this.failedCount()) == size) return false;
    return true;
  }

  markItemFailed(outboxItem: OutboxItem, e?: string) {
    Sentry.captureException(
      new Error(`Failed to send outbox item ${outboxItem.id}: ${e}`),
    );
    return this.db.outbox_items.put({
      ...outboxItem,
      failedDate: new Date(),
      failedMessage: e || __("Unknown error"),
    });
  }

  // For when the user deletes an entry locally. Stop trying to
  // push it to the server.
  public removeItemsForEntry(journalId: string, entryId: string) {
    return this.db.outbox_items
      .where({ journalId: journalId, entryId: entryId })
      .and((item) => item.action !== "DELETE")
      .delete();
  }

  // For when the user deletes a moment.
  // Stop trying to push it to the server.
  public removeItemsForMoment(
    journalId: string,
    entryId: string,
    momentId: string,
  ) {
    return this.db.outbox_items
      .where({ journalId: journalId, entryId: entryId, momentId: momentId })
      .and((item) => item.action !== "DELETE")
      .delete();
  }

  private async shouldPush() {
    // A work around for a bug where we can send the same item twice if processed too quickly.
    // We have a lock and a timeout to prevent pushing too often.
    if (this.pushLocked) {
      return false;
    }
    const now = Date.now();
    if (
      this.lastPushDateTime &&
      now - this.lastPushDateTime < SYNC_DELAY_TIME
    ) {
      this.logDebug("Skip Push", {
        lastPushed: new Date(this.lastPushDateTime).toISOString(),
        now: new Date(now).toISOString(),
        diff: now - this.lastPushDateTime,
      });
      return false;
    }
    this.lastPushDateTime = Date.now();
    return this.hasItemsToPush();
  }

  // One by one, process everything in the outbox.
  public async processAll(
    callback: (outboxItem: OutboxItem) => Promise<OutboxResult>,
  ): Promise<OutboxResult | void> {
    const shouldPush = await this.shouldPush();
    this.logDebug("Processing, has items to push", shouldPush);
    if (!shouldPush) {
      return;
    }
    this.pushLocked = true;
    const outboxQueue = await this.all();
    let skipEntryMove = false;
    while (outboxQueue.length) {
      const outboxItem = outboxQueue.shift();
      if (!outboxItem) {
        continue;
      }
      const isMove = isEntryMoveSendable(outboxItem);
      if (isEntrySendable(outboxItem)) {
        // Check if the entry has an ongoing move
        const entryMoves = await this.getAllEntryMoves(outboxItem.entryId);
        let currentMove: EntryMoveSendable | null = null;
        let pendingMove: EntryMoveSendable | null = null;
        for (const move of entryMoves) {
          if (move.id === outboxItem.id) {
            // The move corresponding to the current outbox item
            currentMove = move;
          } else if (move.isNextMove === true) {
            // The move that is currently being processed
            pendingMove = move;
          }
        }
        if (
          // Skip updates for entry if there is an ongoing move for it
          (entryMoves.length > 0 && !isMove) ||
          // Skip processing moves if there is another ongoing move for the same entry
          (isMove && pendingMove && currentMove?.id !== pendingMove?.id) ||
          // Skip processing moves if there is another move that we already checked
          // and it's still being processed.
          (isMove && currentMove?.entryMoveId && skipEntryMove)
        ) {
          continue;
        }
      }
      if (!this.itemIsRetryableOrFresh(outboxItem)) {
        outboxQueue.push(outboxItem);
        continue;
      }
      if (!isMove) {
        await this.db.outbox_items.delete(outboxItem.id);
      }
      try {
        const result = await callback(outboxItem);
        if (isMove) {
          // This will only happen if the entry move is pending/running
          if (result.result === "pending") {
            if (result.extraData) {
              // If the move is still running, set the skip flag
              // so we can avoid checking for status of other moves.
              skipEntryMove = true;
              // We need to add the entry move back to the queue with the updated move ID
              const entryMoveId = result.extraData.entryMoveId;
              await this.db.outbox_items.update(outboxItem.id, {
                ...outboxItem,
                entryMoveId,
                isNextMove: true,
              } as EntryMoveSendable);
            }
            continue;
          }
          skipEntryMove = false;
        }

        if (result.result === "failed") {
          await this.markItemFailed(outboxItem, result.message);
          this.pushLocked = false;
          return result;
        }

        this.logDebug("Finished processing item. Removing: ", outboxItem);
        await this.db.outbox_items.delete(outboxItem.id);
      } catch (e) {
        let msg;
        if (e instanceof Error) {
          msg = e.message;
        } else if (typeof e === "string") {
          msg = e;
        } else {
          msg = String(e);
        }
        await this.markItemFailed(outboxItem, msg);
      }
    }
    this.pushLocked = false;
  }

  async test_makeTestOutboxItem(
    journal_id: string,
    entry_id: string,
  ): Promise<EntrySendable> {
    const x: EntrySendable = {
      type: "Entry",
      action: "CREATE",
      id: entry_id + journal_id,
      journalId: journal_id,
      entryId: entry_id,
    };
    await this.db.outbox_items.add({ ...x, userModifiedAt: Date.now() });
    return x;
  }
}
