import { __, sprintf } from "@wordpress/i18n";
import { format } from "date-fns";
import { getWeekStartByLocale } from "weekstart";

import { EntryModel } from "@/data/models/EntryModel";

const isValidTimeZone = (timezone: string) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    return false;
  }
  if (typeof timezone !== "string") {
    return false;
  }
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });
    return true;
  } catch {
    return false;
  }
};

// Some entries have a timezone not valid for the format function
export const parseTimeZone = (timeZone: string) => {
  if (isValidTimeZone(timeZone)) {
    return timeZone;
  } else {
    if (timeZone.includes("GMT") || timeZone.includes("UTC")) {
      const regexp = /(GMT|UTC)([+|-])(\d)(\d)/;
      const match = "GMT-0600".match(regexp);
      if (match && match?.length >= 5) {
        const referenceTimeZone = match[1];
        const sign = match[2] === "+" ? "-" : "+";
        const hours = parseInt(match[3] + match[4]);
        return `Etc/${referenceTimeZone}${sign}${hours}`;
      }
    }
  }
  // default to locale timezone
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getIsToday = (date: Date) =>
  new Date().setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0);
export const getIsYesterday = (date: Date) =>
  new Date().setHours(0, 0, 0, 0) === date.setDate(date.getDate() + 1);
export const getIsThisYear = (date: Date) =>
  new Date().getFullYear() === date.getFullYear();

export const getRelativeTime = (
  date: Date,
  format?: Intl.DateTimeFormatOptions,
  timeZone?: string,
) => {
  const parsedTimeZone = timeZone ? parseTimeZone(timeZone) : timeZone;
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  // Calculate time differences in seconds, minutes, hours, and days
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (days >= 7 && !!format) {
    // Display the full date if it's older than 1 week and we want to cut off calculations
    return date.toLocaleDateString(undefined, {
      ...format,
      timeZone: parsedTimeZone,
    });
  }

  if (seconds < 60) {
    return __("Just now");
  } else if (minutes < 60) {
    return sprintf(__("%dm ago"), minutes);
  } else if (hours < 24) {
    return sprintf(__("%dh ago"), hours);
  } else if (days < 7) {
    return sprintf(__("%dd ago"), days);
  } else if (months < 1) {
    return sprintf(__("%dw ago"), weeks);
  } else if (months < 12) {
    return sprintf(__("%dmo ago"), months);
  } else {
    return sprintf(__("%dyr ago"), years);
  }
};

export const roundDateToMonth = (timestamp: number) => {
  const date = new Date(timestamp);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export const normalizeDateForComparison = (
  timestamp: number,
  timezone?: string,
) => {
  const tz = timezone
    ? parseTimeZone(timezone)
    : Intl.DateTimeFormat().resolvedOptions().timeZone;
  const date = new Date(timestamp);
  const dateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: tz,
  });
  return dateString;
};

export const toTwoDigits = (num: number) => num.toString().padStart(2, "0");

export const formatDuration = (duration: number | null | undefined): string => {
  if (!duration) {
    return "";
  }
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
  return `${toTwoDigits(hours)}:${toTwoDigits(minutes)}:${toTwoDigits(
    seconds,
  )}`;
};

export function numberToDate(number: number): Date {
  return new Date(number);
}

export function dateTimeToString(
  date: Date,
  timeZone?: string | undefined,
  locale?: string,
): string {
  const parsedTimeZone = timeZone ? parseTimeZone(timeZone) : timeZone;
  return date.toLocaleTimeString(locale, {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: parsedTimeZone,
  });
}

export function dateToString(
  date: Date,
  timeZone?: string | undefined,
  locale?: string,
): string {
  const parsedTimeZone = timeZone ? parseTimeZone(timeZone) : timeZone;
  return date.toLocaleDateString(locale, {
    weekday: "long",
    day: "numeric",
    month: "short",
    year: "numeric",
    timeZone: parsedTimeZone,
  });
}

export const getMonthAsString = (date: Date) => {
  return format(date, "MMMM yyyy");
};

export const entryDateNumberToString = (entry: EntryModel, locale?: string) => {
  const timeZone =
    entry.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = numberToDate(entry.date);
  return entry.isAllDay
    ? dateToString(date, timeZone, locale)
    : dateTimeToString(date, timeZone, locale);
};

export const getFirstDayOfWeek = (date: Date) => {
  const firstDayDigit = getWeekStartByLocale(navigator.language);

  const compareDate = date.getDay();
  const diff = (compareDate - firstDayDigit + 7) % 7;
  const firstDate = new Date(date);
  firstDate.setDate(date.getDate() - diff);

  return firstDate.setHours(0, 0, 0, 0);
};
