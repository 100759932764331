// Returns true if the passed paramenter is a non zero length Uint8Array which means it should be a valid media item
// Returns false if the passed parameter is one of our known error cases
// Returns null if the passed parameter is not one of our known error cases
export const isValidMedia = (maybeMedia: Uint8Array | GetMediaError) => {
  if (maybeMedia instanceof Uint8Array) {
    return maybeMedia.length > 0;
  }

  if (validGetMediaErrors.includes(maybeMedia)) {
    return false;
  }

  return null;
};

export const validGetMediaErrors = [
  "COULD_NOT_DECRYPT",
  "COULD_NOT_COMPLETE_DOWNLOAD",
  "COULD_NOT_DOWNLOAD",
  "NO_LOCAL_MD5",
  "NO_REMOTE_MD5",
  "UNKNOWN",
] as const;

export type GetMediaError = (typeof validGetMediaErrors)[number];

export const getMediaKey = (
  id: string,
  journalId: string,
  isThumbnail: boolean,
) => {
  return `${journalId}-${id}-${isThumbnail}`;
};
